import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgApexchartsModule } from 'ng-apexcharts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JerSuggestMultiselectComponent } from './jer-suggest-multiselect/comp-jer-suggest-multiselect.component';
import { transformExactSearchPipe } from './pipes/transform-exactSearch.pipe';
import { transformSemanticOrStringSearchPipe } from './pipes/transform-semanticOrStringSearch.pipe';



// TRANSLATION

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClickOutsideModule } from 'ng-click-outside';
import { MechanicsService } from './_services/mechanics.service';
// https://github.com/ngx-translate/core
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n-jer/', '.json');
}


@NgModule({
	declarations: [
		AppComponent,
		JerSuggestMultiselectComponent,

		transformExactSearchPipe,
		transformSemanticOrStringSearchPipe,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,

		FormsModule,
		ReactiveFormsModule,
		
		HttpClientModule,
		NgApexchartsModule,

		
		// https://github.com/ngx-translate/core
		TranslateModule.forRoot({

			defaultLanguage: 'en',

			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),

		ClickOutsideModule,

	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
