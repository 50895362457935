

/*

	This configuration loads the WIPO navbar, which produces errors in the console and breaks the Angular app when served locally. It needs to be run on WIPO servers for being able to load all files correctly

	So, I have created in package.json alternative build scripts (buildProdLocal and serveProdLocal) that build with --prod, but do not swap environment.ts with environment.prod.ts, so it's a production that can be run locally

*/

export const environment = {
	env: "awsDev",
	production : false,
	wipoComponentsPath: "https://www3dev.wipo.int/webcomponents", // DEV components
	// wipoComponentsPath: "https://www5.wipo.int/webcomponents", // ACC components
	appUrl: "https://goods-and-services-assistant.gbd.globaldb.dev.web1.wipo.int", // used in WIPO Navbar
	apiURL : "https://public-api.gbd.globaldb.dev.web1.wipo.int/gs-assistant",
};