import { Pipe, PipeTransform } from '@angular/core';
import { ApexOptions, ApiResponse, CustomOccurencesTimelineObj, Match, WOption } from '../interfaces';
import { deepClone } from '../utils';
import { MechanicsService } from '../_services/mechanics.service';

let cache:any = null;

@Pipe({
	name: 'transformExactSearch'
})
export class transformExactSearchPipe implements PipeTransform {

	constructor(private ms:MechanicsService) {

	}

	transform(apiResponse: ApiResponse, ...args: any[]): any[] {

		const l = `transformExactSearch pipe - `

		if(this.ms.isPipesLocked){
			// console.log(`${l}Pipes are locked, skipping - Returning cache = `, cache)
			return cache
		}

		// console.log(`16${l}raw apiResponse = `, deepClone(apiResponse))
		// console.log(`17${l}args = `, args)

		const state: any = args[0];
		let highest: number = 0;

		// console.log(`${l}state : `, state)

		/*
			LOG : state = 
					{
						"term": "sushi",
						"searchType": "exactSearch",
						"inputLang": "en",
						"outputLang": "en",
						"sort": "office",
						"showNullResults": false,
						"offices": [],
						"niceClass": "",
						"columnSortBy": "similarityScore",
						"columnSortOrder": 1,
						"debounceTime": 400
					}
		*/

		if (!apiResponse.matches || !apiResponse.matches.length) {
			// console.info(`${l}No match, returning []`)
			return []
		}

		const onlyFromOffices: string[] = (state.offices || []).map((officeObj: WOption) => officeObj.value);

		// console.log(`${l}iterating apiResponse.matches = `, apiResponse.matches)

		for (let match of apiResponse.matches) { // One match is one NiceClass (in general, there's only 1 or 2 matches)

			// console.log(`${l}33 match = `, match)

			match.transformedData = (match.nbOccurencesTimeline || []).map((timelineObj: number[] | any, index: number): any => {

				// console.log(`${l}35 timelineObj = `, timelineObj)

				/* LOG : timelineObj = 
					"office": "AU",
					"data": [35,67,51,66,70,68,89,101,58,79,34,0],
					"total": 718,
					"show": true
				}
				*/

				highest = Math.max(...timelineObj, highest)

				// console.log(`${l}timelineObj=`, timelineObj)

				// console.log(`${l}intermediate highest = `, highest, "~~~~~~~~~~")

				// Add any property you need per chart in this object (full country name, etc.)
				let toReturn: any = {
					office: apiResponse.offices[index],
					data: timelineObj,
					total: timelineObj.reduce((partialSum: number, a: number) => partialSum + a, 0),
				}

				toReturn["show"] = state.showNullResults ? true : toReturn.total > 0;

				return toReturn
			})



			if (onlyFromOffices.length) {
				match.transformedData = match.transformedData.filter((occ: any) => onlyFromOffices.includes(occ.office))
			}

			match.transformedData.sort((a: any, b: any) => {
				const compareA = a[state.sort]
				const compareB = b[state.sort]

				if (compareA.localeCompare) { // string
					return compareA.localeCompare(compareB)
				} else { // number
					return compareA > compareB ? 1 : -1
				}
			})
		}

		// console.log(`${l}final highest = `, highest, "~~~~~~~~~~")

		// console.log(`${l}transformed apiResponse = `, deepClone(apiResponse))

		let chartsOptions: Partial<ApexOptions>[] | any[] = []

		let match: Match

		if (state.niceClass) {
			match = apiResponse.matches.find(m => m.NiceClass === state.niceClass) as Match
		} else {
			match = apiResponse.matches[0]
		}

		for (let index in (match.transformedData || [])) {

			// if (index === "2") break;

			const obj: CustomOccurencesTimelineObj = match.transformedData[index]; // This is the object I'm producing a few lines above, in the for loop

			if (!obj.show) continue;

			const yearsRange: number = obj.data?.length || 1;

			chartsOptions.push({
				series: [
					obj
				],
				chart: {
					// height: 350, // px
					type: "area",
					toolbar: {
						show: false
					},
				},
				title: {
					text: obj.office,
					style: {
						fontSize: '25px',
						fontWeight: 'bold',
					},
				},
				xaxis: {
					categories: [...Array(yearsRange).keys()].map(x => x + apiResponse.startYear),
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false,
					},
				},
				yaxis: {
					max: highest,
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false,
					},
				},
				grid: {
					show: false
				}

				// Heads up! Every key that's added here must be reflected in the <apx-chart> component!
				// ex : [grid]="chartOptions.grid"
				// Otherwise it won't be taken into account

			});
		}

		// console.log(`${l}Returning chartsOptions = `, chartsOptions)

		cache = chartsOptions;

		// console.log(`${l}locking pipes`)
		this.ms.isPipesLocked = true; // Forbidding the transforms pipes to run every time a keyboard key is hit

		return chartsOptions;
	}
}
