import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, from, lastValueFrom, Observable, Subject } from 'rxjs';
import { MechanicsService } from './mechanics.service';
import { HttpClient } from '@angular/common/http';
import { WOption } from '../interfaces';
import { offices } from '../imports/offices';
import { deepClone } from '../utils';

interface HardcodedLists {
	offices: WOption[];
}

@Injectable({
	providedIn: 'root'
})
export class OptionsListService {

	public hardcodedLists: HardcodedLists = {
		// STATIC LISTS - built in buildHardcodedLists()
		offices: [], // List of all IP offices
	}
	private hardcodedKeys = Object.keys(this.hardcodedLists)

	/* 
		suggestions : empty at start. Is reused for everything with a suggestion list : brandName, viennaClass, niceClass, usDesignClass, etc.
		  w-select-many takes WOption[], but w-input-autocomplete takes string[]...
	*/

	public suggestions: WOption[] = []; // | string[]
	public dbInfoCache: any = {};
	public $suggestTerms = new Subject<string>()
	public isSuggesting: boolean = false; // boolean is not enough. I need to know what the service is currently suggesting, in order to not display the loading gif in every suggestion list on the page. isSuggesting="niceClass"
	public noSuggestions: boolean = false; // This is not the same as suggestions.length===0. It can be 0 at the beginning. But if it's 0 after a search, then this flag turns true.
	public what: string = ""; // storing the type of what we're looking for (niceClass, viennaClass, etc.) When loading, isSuggesting will take this value

	constructor(public ms: MechanicsService,
		public http: HttpClient) {

			
			from(this.$suggestTerms)
			.pipe(
				filter(res => res.length > 1),
				distinctUntilChanged(),
				debounceTime(400)
			)
			.subscribe((params) => {
				// console.log(`$suggestTerms subscription - params = `, params)
				
			})
			
			this.buildStuff()
		}

	buildStuff() {

		// Dirty hack to wait for the translations to be loaded
		const intervalWaitForTranslations = setInterval(() => {
			if (!this.ms.translations) return
			this.buildHardcodedLists()
			clearInterval(intervalWaitForTranslations)
		},100)
	}

	async buildHardcodedLists() {

		const l = `ols.buildHardcodedLists() - `

		/*
			Building the static autocomplete lists (countries, IP offices...)
			Non-static (suggested) lists are done on the fly (viennaClass, usDesignClass)
		*/

		try {

			this.hardcodedLists.offices = offices.map((cc: string): WOption => ({
				value: cc,
				label: "(" + cc + ") " + this.ms.translate("office." + cc),
				label2: (cc + this.ms.translate("office." + cc)).toLowerCase() // lowercase, for faster search/match
			}))


			// console.log(`${l}Built hardcoded offices list = `, deepClone(this.hardcodedLists.offices))

		} catch (err) {

			console.log(`${l}Caught error (couldn't fetch DbInfo): `, err)
		}

		// console.log(`${l}Have built this.hardcodedLists = `, this.hardcodedLists)
	}

	// what: applicant / representative / brandName
	suggest($event: KeyboardEvent, what: string): void {

		const l: string = `OLS doSuggest() - `

		// console.log(`${l}$event.key = `, $event.key)

		this.noSuggestions = false
		this.what = what;

		const word = (<HTMLInputElement>$event.target).value.trim()
		if (!word?.length) {
			return
		}

		if (["Enter", "ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown", "Space"].includes($event.key)) {
			return
		}

		this.suggestHardcoded(word)
		return
	}

	suggestHardcoded(word: string) {

		const l = `ols.suggestHardcoded() - `

		// console.log(`${l}Suggesting from hardcoded list '${this.what}' = `, (this.hardcodedLists as any)[this.what]) // SHUT UP TYPESCRIPT!!

		word = word.toLowerCase();
		this.suggestions = ((this.hardcodedLists as any)[this.what] || []).filter((wOption: WOption) => wOption.label2!.includes(word))

		// console.log(`${l}this.suggestions = `, this.suggestions)
	}
}
