
declare const CryptoJS: any;
const chut = "8?)i_~Nk7qv0IX;";

export const deepClone = (obj:any, maxArrayLength = Infinity): any => { // https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object

	const l = `utils.deepClone() - `

	// console.log(`${l}deepcloning : `, obj)

	let copy:any;

	// console.log(`Deepcloning (reduce ${reduce})`);

	// Handle strings
	if (typeof (obj) === "string") {
		return "" + obj;
	}

	// Handle the 3 simple types, and null or undefined
	if (!obj || (`object` != typeof obj)) {

		// console.log(`${l}passed object is not an object! typeof(obj)='${typeof(obj)}'`)

		return obj;
	}

	// Handle Date
	if (obj instanceof Date) {
		copy = new Date();
		copy.setTime(obj.getTime());
		return copy;
	}

	// Handle Array
	if (obj instanceof Array) {
		copy = [];

		const maxElems: number = Math.min(maxArrayLength, obj.length)

		for (let i = 0; i < maxElems; i++) {
			copy[i] = deepClone(obj[i], maxArrayLength);
		}

		if (maxArrayLength && obj.length > maxElems) copy.push(`(${obj.length - maxElems} more elements)`);

		return copy;
	}

	// Handle Object
	if (obj instanceof Object) {
		copy = {};
		const totalKeysCount = Object.keys(obj).length
		const maxKeys: number = Math.min(maxArrayLength, totalKeysCount)
		let keysCount: number = 0;

		for (let attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = deepClone(obj[attr], maxArrayLength);
			keysCount++;
			if (maxArrayLength && keysCount > maxKeys) break;
		}

		if (maxArrayLength && totalKeysCount > maxKeys) copy["(Unshown keys)"] = totalKeysCount - maxKeys

		return copy;
	}

	throw new Error(`Unable to copy obj! Its type isn't supported.`);

}

export function compareDictionaries( d1:any, d2:any, ignoreKeys:string[] ):boolean
{
	//Shollow compare.
    // quick check for the same object
    if( d1 == d2 )
        return true;

    // check for null
    if( d1 == null || d2 == null )
        return false;

    // go through the keys in d1 and check if they're in d2 - also keep a count
    var count:number = 0;
    for( var key in d1 )
    {
		count++;
		if (ignoreKeys.includes(key)){
			continue
		}
        // check if the key exists
        if( !( key in d2 ) ){
            return false;
		}

        // check that the values are the same
        if( d1[key] != d2[key] ){
            return false;
		}

    }
    // now just make sure d2 has the same number of keys
    var count2:number = 0;
    for( key in d2 )
        count2++;

    // return if they're the same size
    return ( count == count2 );
}


export const decrypt = (crypted: any): any => {

	const l = `decrypt() - `

	/*
		Passed data should be a string.
		Will attempt to decrypt it.
		If can't decrypt, sends back the data as is.
		This makes the Angular app accept encrypted and non-encrypted responses from the server.
	*/

	// console.log(`${l}Received data to decrypt (type='${typeof(crypted)}') = `, deepClone(crypted))

	if (typeof (crypted) !== "string") {
		// console.log(`${l}Data to decrypt = `, crypted)
		// console.log(`${l} - Data to decrypt is not a string, skipping`)
		return crypted
	}

	// On Localhost, the data may not be encrypted, but the ResponseType is still "string", so we'll get a stringified JSON anyway. I need to simply parse it, without decryption

	let decrypted;

	try {
		decrypted = JSON.parse(crypted);
		return decrypted;
	} catch (err) {
		// console.log(`${l}Could not parse response directly as JSON, attempting to decrypt it.`)
	}

	// OK so now, I very likely have an encrypted string.

	// console.time(`${l}decryption took`)

	let originalStringified: string;

	try {
		const bytes: any = CryptoJS.AES.decrypt(crypted, chut);

		originalStringified = bytes.toString(CryptoJS.enc.Utf8);

		// console.log(`${l}originalStringified=`, originalStringified)

		decrypted = JSON.parse(originalStringified);

	} catch (err) {
		// console.log(`${l}Could not JSON.parse decrypted content. Probably not an object. Sending back decrypted string as is.`)
	}

	// console.timeEnd(`${l}decryption took`)

	return decrypted || originalStringified
}

export const trimPluses = (input:string) : string => input.replace(/ *\+ */g , "+"); // trimming spaces around "+"