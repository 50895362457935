import { Pipe, PipeTransform } from '@angular/core';
import { ApiResponse, Match, WOption } from '../interfaces';

import { deepClone, trimPluses } from '../utils';
import { MechanicsService } from '../_services/mechanics.service';


let timeoutDebounce = null
let cache: any = null;

@Pipe({
	name: 'transformSemanticOrStringSearch'
})
export class transformSemanticOrStringSearchPipe implements PipeTransform {

	constructor(private ms: MechanicsService) {

	}

	transform(apiResponse: ApiResponse, ...args: any[]): Partial<ApiResponse> {

		const l = `transformSemanticOrStringSearch - `

		if (this.ms.isPipesLocked) {
			// console.log(`${l}Pipes are locked, skipping - Returning cache = `, cache)
			return cache
		}

		// console.log(`${l}raw apiResponse = `, deepClone(apiResponse), `args = `, args)

		const typedTerm: string = decodeURIComponent(apiResponse.original_term || "");

		if (!apiResponse.matches || !apiResponse.matches.length) {
			// console.info(`${l}No match, returning []`)
			return {
				matches: []
			}
		}
		// console.time(`Highlighting took`)

		const state: any = args[0];

		const onlyFromOffices: string[] = (state.offices || []).map((officeObj: WOption) => officeObj.value);

		apiResponse.matches = apiResponse.matches.map((match: Match, index: number): any => {

			let l = `apiResponse transforms - `

			// console.log(`${l}28 match = `, deepClone(match))

			match.nbOccurences = ((match.nbOccurences || []) as number[]).map((occ: number, index: number) => {
				let toReturn = {
					office: apiResponse.offices[index],
					value: occ
				}
				return toReturn
			});

			// console.log(`${l}match.term = `, match.term);
			match.term = match.term || ""; // can be null...

			match.term_for_link = match.term.trim().replace(/_/g, " ");

			if (onlyFromOffices.length) {
				match.nbTotalOccurences = 0
				match.nbOccurences = match.nbOccurences.filter((occ: any) => onlyFromOffices.includes(occ.office));
				match.nbOccurences.forEach((occ: any) => match.nbTotalOccurences += occ.value)
			}

			/* ~~~~~~ START HIGHLIGHTING ~~~~~~

				sushi --> <em>Sushi</em>

				electric car --> <em>Electric</em> <em>car</em>

				electric_car --> <em>Electric</em> <em>car</em>
			*/

			l = `highlighting - `

			const term1 = trimPluses(typedTerm.trim());
			const term2 = trimPluses(args[0].term.trim())

			// console.log(`${l}term1==='${term1}', term2==='${term2}'`)

			if (term1 === term2) {
				try {
					const typedSplit: string[] = term1.replace(/[_\+]/g, " ").split(" ").filter(m => m.length);

					// console.log(`\n${l}typedSplit = `, typedSplit)

					for (let word of typedSplit) {

						// console.log(`${l}word = ${word}`)

						match.highlighted = (match.highlighted || match.term).replace(new RegExp(word, "ig"), (matched) => {
							// console.log(`${l}matched = `, matched)
							return `<em>${matched}</em>`
						});
					}

				} catch (err) {
					// Regex probably failed because there's a special character like "+"
				}
			}

			// ~~~~~~ END HIGHLIGHTING ~~~~~~

			return match
		})
			.filter((match: Match) => match.nbTotalOccurences > 0 && match.term && (match.similarityScore as unknown) !== "NaN");

		// console.timeEnd(`Highlighting took`)

		// console.log(`${l}loopsCount = ${loopsCount}`)

		// apiResponse.matches.reverse(); // API results arrive sorted by inverse score (?)

		// console.log(`${l}state : `, state)
		const { columnSortBy, columnSortOrder, ...rest } = state;
		// console.log(`${l}columnSortBy='${columnSortBy}', columnSortOrder='${columnSortOrder}'`)
		// columnSortBy = term, similarityScore, NiceClass, nbTotalOccurences
		// columnSortOrder = 1, -1

		apiResponse.matches.sort((a: any, b: any) => {

			// console.log(`${l}a[columnSortBy]='${a[columnSortBy]}', b[columnSortBy]='${b[columnSortBy]}'`)

			if (a[columnSortBy] !== b[columnSortBy]) {
				if (b[columnSortBy].localeCompare) {
					return -1 * columnSortOrder * (b[columnSortBy].localeCompare(a[columnSortBy]))
				}
				return columnSortOrder * (b[columnSortBy] - a[columnSortBy])
			}
			return b.nbTotalOccurences - a.nbTotalOccurences
		})

		cache = apiResponse;

		// console.log(`${l}locking pipes`)
		this.ms.isPipesLocked = true; // Forbidding the transforms pipes to run every time a keyboard key is hit

		// console.log(`${l}returning apiResponse = `, apiResponse)
		return apiResponse
	}

}
