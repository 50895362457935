import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import {
	Observable,
	Subject,
	Subscription,
	catchError,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	filter,
	from,
	map,
	merge,
	of,
	startWith,
	throwError,
	withLatestFrom,
	tap,
	switchMap,
	retry,
	pairwise,
	timer,
	debounce
} from 'rxjs';
import { MechanicsService } from './_services/mechanics.service';
import { SearchService } from './_services/search.service';
import { environment } from "../environments/environment"

import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexTitleSubtitle,
} from "ng-apexcharts";
import { decrypt, deepClone, compareDictionaries, trimPluses } from './utils';

// https://stackoverflow.com/a/70483901
import { ApexOptions, ApiResponse, Match } from './interfaces';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

const ignoreKeys = ["Enter", "Space"];

const defaultDebounceTime: number = 400;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	// changeDetection: ChangeDetectionStrategy.OnPush --> NO! This is SHIT and SHIT. When I type "sushi" it only registers as "sush", is always one letter behind. Commenting this out makes the app work. Also, even though ss.callSearch() does return an observable, the | async pipe is not triggered. OnPush is CRAP, as far as I can tell
})
export class AppComponent {

	public helpOptions: any[] = [];
	public helpMore: any[] = [];

	public formGroup: UntypedFormGroup = new UntypedFormGroup({
		term: new UntypedFormControl(''),
		searchType: new UntypedFormControl('stringSearch'),
		inputLang: new UntypedFormControl('en'),
		outputLang: new UntypedFormControl('en'),
		sort: new UntypedFormControl('office'),
		showNullResults: new UntypedFormControl(false),
		offices: new UntypedFormControl([]),

		niceClass: new UntypedFormControl(''), // Exact search : radio choice of which Nice Class is graphed
		// refresh: new FormControl(0), // used to refresh the search. Push a random number here.

		columnSortBy: new UntypedFormControl('similarityScore'),
		columnSortOrder: new UntypedFormControl(1),

		debounceTime: new UntypedFormControl(defaultDebounceTime)
	});
	public apiResponse: Observable<ApiResponse>;
	public errorObj: Error | null = null;
	public alreadyExecutedSearch: boolean = false

	// https://apexcharts.com/docs/angular-charts
	//- @ViewChild("chart") chart: ChartComponent; ? What is this for? (in the doc)
	public chartOptions: Partial<ApexOptions> | any; // Shut up, Typescript!! And compile my goddamn project
	public previousState: any // so as to load it when clicking "back"
	public semanticTermIndex: string = "0"; // When doing a semantic search, this is the index of term we're currently graphing ('sushi' or 'sashimi' etc) to get the corresponding graph
	private $apexcharts: any
	public environment = environment

	public enhancingGraph: string | null = null; // string | null ; office initials if a graph is enhanced, null in 3D mode

	public tableColumns = []; // built in the constructor

	public languageOptions: any[] = [];

	public lastSearchedTerm: string = ""

	constructor(public ms: MechanicsService,
		public ss: SearchService) {

		const l = `app.constructor - `
		this.languageOptions = ms.availableLangs.map(lang => ({ "code": lang }))

		this.apiResponse = this.formGroup.valueChanges.pipe(
			pairwise(),
			filter(([oldFormData, newFormData]): boolean => {

				const l = `valueChanges filter - `

				// console.log(`\n${l}oldFormData = `, oldFormData)
				// console.log(`${l}newFormData = `, newFormData)

				// console.log(`${l}oldFormData.debounceTime='${oldFormData.debounceTime}', newFormData.debounceTime='${newFormData.debounceTime}', will search? ${oldFormData.debounceTime === 0 && newFormData.debounceTime === defaultDebounceTime}`)

				this.alreadyExecutedSearch = compareDictionaries(oldFormData, newFormData, ["debounceTime"]);
				// console.log(l, oldFormData.term, newFormData.term, this.alreadyExecutedSearch)

				if (oldFormData.debounceTime === 0 && newFormData.debounceTime === defaultDebounceTime) {
					// After searching with the Enter key (which sets the debounce to 0 in order to search immediately), I reset it to its default value. BUT of course, this will trigger an unwanted search, I want it to be silent. So I catch it here and avoid the call
					return false
				}

				newFormData.term = trimPluses(newFormData.term)

				if (oldFormData.term !== newFormData.term || newFormData.term.length < 2) {
					// Christophe does not want the search to be triggered on type/debounce, only when the user hits Enter
					this.alreadyExecutedSearch = false
					return false
				}

				// I don't want to remake an API call when we only change the column sort order
				if (oldFormData.columnSortBy !== newFormData.columnSortBy
					||
					oldFormData.columnSortOrder !== newFormData.columnSortOrder
				) {
					return false
				}

				// console.log(`${l}newFormData.term = '${newFormData.term}'`)

				return true
			}),
			distinctUntilChanged(),

			tap(() => {
				this.ss.isLoading = true; // Immediately hiding stuff we shouldn't see anymore, while it"s sloading, without waiting for the debounce
			}),

			debounce(() => timer(this.getFromGroupKey('debounceTime'))), // dynamic debounce time :D
			switchMap(this.switchMapFunc.bind(this)),

			catchError(err => { // Catch HTTP errors here
				this.ss.isLoading = false;
				this.errorObj = err
				console.error(`${l}caught error = `, err)
				return throwError(() => err); // Apparently catchError is just supposed to return this (?)
			})
		) as Observable<ApiResponse>;

		this.helpOptions = [  // Optional, set custom links for Contact and FAQ  (default = WIPO CONTACT)
			{
				"code": "contact", // - code property is mandatory
				"link": `https://www3.wipo.int/contact/${this.ms.lang}/area.jsp?area=branddb` // - link property is optional
			}
		];

		if (true || !this.ms.isLocalHost) {
			this.loadWipoNavbar()
		}

		document.addEventListener('wipoLanguageChange', async ($event: CustomEvent) => {

			// Dirty hach to wait for TranslationService observable to actually change the language

			const originalTranslation = "" + this.ms.translate("semantic_search.term");
			let antiloop = 0;

			while (antiloop < 100 && this.ms.translate("semantic_search.term") === originalTranslation) {
				await new Promise(r => setTimeout(r, 10))
				antiloop++
			}

			// Now we're sure the language has changed
			this.buildTableColums()
		})
	}

	async ngAfterViewInit() {

		const l = `appcomponent ngAfterViewInit() - `

		/*
		this.formGroup.patchValue({
			term: "sush"
		})
		this.formGroup.patchValue({
			term: "sushi"
		})
		*/

		while (!this.ms.translations) {
			await new Promise(r => setTimeout(r, 50))
		}

		this.buildTableColums()

	}

	afterHttpCall() {

		const l = `appComponent afterHttpCall() - `

		this.ss.isLoading = false;

		setTimeout(() => {
			// Hack (ApexCharts apparently doesn't kow how to do hide "0" labels so I'm selecting and hiding them with pure JS)
			for (let a of Array.from(document.querySelectorAll(".apexcharts-data-labels"))) {
				const b: HTMLElement = a as HTMLElement;
				const textContent: string = "" + b.textContent
				// console.log(`elem.textContent = ${textContent}`)
				if (/^0+$/.test(textContent)) {
					// b.style.display = "none"
					a.remove()
				}
			}
		})

		if (this.getFromGroupKey('debounceTime') !== defaultDebounceTime) {
			this.formGroup.patchValue({ debounceTime: defaultDebounceTime }) // REsetting default debounce after searching with Enter key
		}

		// console.log(`${l}unlocking pipes`)
		this.ms.isPipesLocked = false; // allowing a transform pipe to run just once
	}

	back() {
		this.formGroup.patchValue(deepClone(this.previousState))
		this.previousState = null
		this.triggerSearch()
	}


	buildTableColums() {


		const l = `appComponent buildTableColums() - `

		this.tableColumns = [
			{
				label: this.ms.translate("semantic_search.term"),
				value: "term"
			},
			{
				label: this.ms.translate("semantic_search.semantic_proximity"),
				value: "similarityScore",
			},
			{
				label: this.ms.translate("semantic_search.nice_class"),
				value: "NiceClass"
			},
			{
				label: this.ms.translate("semantic_search.cumulated_number"),
				value: "nbTotalOccurences"
			},
		]
		// console.log(`${l}this.tableColumns = `, this.tableColumns)
	}


	get canSearch() {
		return this.getFromGroupKey('term').length > 1 && !this.alreadyExecutedSearch;
	}

	get canSearchBrandDB() {
		return this.getFromGroupKey('term').length > 1 && this.getFromGroupKey('searchType') == 'exactSearch'
	}

	get gbdLink(): string {

		const l = `gbdLink() - `

		/*
			?sort=score%20desc
			&start=0
			&rows=30
			&asStructure={
				"_id":"3219",
				"boolean":"AND",
				"bricks":[
					{
						"_id":"321a",
						"key":"goodsServices",
						"value":"sushi",
						"strategy":"Terms" --> "Terms" if exact search, "" if not
					}
				]
			}
		*/

		let today = new Date(),
			thisYear = today.getFullYear(),
			thisMonth: number | string = today.getMonth() + 1,
			thisDay: number | string = today.getDate();

		thisMonth = thisMonth < 9 ? "0" + thisMonth : "" + thisMonth;
		thisDay = thisDay < 9 ? "0" + thisDay : "" + thisDay;

		const asStructure = {
			"_id": "1",
			"boolean": "AND",
			"bricks": [
				{
					"_id": "2",
					"key": "goodsServices",
					"value": this.getFromGroupKey("term"),
					"strategy": "Terms" // Exact search
				},
				{
					"_id": "3",
					"key": "regDate",
					"strategy": "Min",
					"value": `${thisYear - 10}-${thisMonth}-${thisDay}`
				},
				{
					"_id": "4",
					"key": "status",
					"value": [
						"Registered",
						"Ended"
					]
				}
			]
		};

		const stringified = JSON.stringify(asStructure);
		const encoded = encodeURIComponent(stringified);

		let link = `https://branddb.wipo.int/${this.ms.lang}/advancedsearch/results?sort=score%20desc&start=0&rows=30&asStructure=${encoded}`;

		for (let obj of this.getFromGroupKey("offices")) {
			// console.log(`${l}obj = `, obj)
			link += `&office=${obj.value}`
		}

		return link
	}

	loadWipoNavbar(): void {

		const l = `appcomponent loadWipoNavbar() - `

		const wipoComponentsPath: string = environment.wipoComponentsPath;

		if (!wipoComponentsPath) {
			// console.log(`${l}No wipoComponentsPath, not loading the WIPO navbar`)
			return
		}

		// console.log(`${l}wipoComponentsPath : `, wipoComponentsPath);

		[
			`${wipoComponentsPath}/polyfills/webcomponents-loader.js`,
			`${wipoComponentsPath}/wipo-init/wipo-init.js`,
			`${wipoComponentsPath}/wipo-navbar/wipo-navbar.js`
		].forEach(src => {
			document.head.appendChild(Object.assign(document.createElement('script'), {
				src,
				async: true
			}));
		});
	};

	getFromGroupKey(key: string) {
		const l = `getFromGroupKey - `
		let formControl = this.formGroup.get(key)
		return formControl?.value || ""
	}

	isRadioChecked(match: Match, index: number): boolean {
		const l = `isRadioChecked - `
		const toReturn: boolean = (this.getFromGroupKey("niceClass") === match.NiceClass) || (index == 0 && !this.getFromGroupKey("niceClass"))
		// console.log(`${l}getFromGroupKey("niceClass")='${this.getFromGroupKey("niceClass")}'; match.NiceClass='${match.NiceClass}'; index='${index}'; returning ${toReturn}`)
		return toReturn
	}

	onRadioClicked(match: Match): void {
		const l = `onRadioClicked() - `
		this.formGroup.patchValue({ niceClass: match.NiceClass })
	}

	enhanceGraph(office?: string) {
		const l = `enhanceGraph() - `
		// console.log(`${l}Enhancing graph '${office}'`)
		this.enhancingGraph = (this.enhancingGraph === null ? office : null)
	}

	performExactSearch(term: string) {
		const l = `performExactSearch() - `
		this.previousState = deepClone(this.formGroup.value)

		// console.log(`${l}outputLang = `,this.formGroup.get("outputLang"))

		this.formGroup.patchValue({
			term,
			inputLang: this.getFromGroupKey("outputLang"), // When the input is "car (English)" and the outputLang is Spanigh, we get results like "carros". If we perform a semantic search, then "carros" is in Spanish, so the inputLang has to change to Spanish, otherwise we won't get any result.
			searchType: "exactSearch"
		})
		this.triggerSearch()
	}

	resultsLine(rawResponse): string {

		return this.ms.translate("results.n_results_for_s")
			.replace("%N", rawResponse.matches[0]?.nbTotalOccurences)
			.replace("%S", this.lastSearchedTerm)

	}

	sortBy(what: string) {
		const l = `sortBy() - `
		// console.log(`${l}${what}`)

		const currentSortField = this.getFromGroupKey("columnSortBy");

		if (currentSortField === what) {
			const currentSortOrder = this.getFromGroupKey("columnSortOrder")
			this.formGroup.patchValue({
				columnSortOrder: currentSortOrder * -1
			})
		} else {
			this.formGroup.patchValue({
				columnSortBy: what
			})
		}
	}

	switchMapFunc([oldFormData, newFormData]): Observable<any> {

		const l = `app.switchMapFunc() - `

		// console.log(`\n${l}oldFormData = `, oldFormData)
		// console.log(`${l}newFormData = `, newFormData)

		const formData = {
			searchType: newFormData.searchType,
			term:  newFormData.term,
			inputLang: newFormData.inputLang,
			outputLang: newFormData.searchType === "semanticSearch" && newFormData.outputLang,
		}

		// console.log(`${l}formData = `,formData)

		for (let key of Object.keys(formData)) {
			try {
				formData[key] = formData[key].trim()
			} catch (err) {
				// not a string, that's fine (boolean)
			}
		}

		return this.ss.callSearch(formData).pipe(
			retry(3),
			map(data => {
				let decrypted: any = decrypt(data);
				if (this.ms.isLocalHost || this.ms.isAwsDev) {
					console.info(`(DEV LOG ONLY)${l} Decrypted response = `, decrypted)
				}
				decrypted['original_term'] = decodeURI(formData.term);
				this.lastSearchedTerm = "" + decrypted['original_term'];
				return decrypted
			}),
			catchError(err => { // Catch HTTP errors here
				this.ss.isLoading = false;
				this.errorObj = err
				return throwError(() => err); // Apparently catchError is just supposed to return this (?)
			}),
			tap(this.afterHttpCall.bind(this))
		)

	}

	triggerSearch() {
		const l = `triggerSearch() - `

		/*
			:| Tricky.
			The template is subscribed to form changes.
			But "Enter" is not a form change.
			So I use this hack : on Enter key, a hidden field in the form is updated : debounceTime.
			The Observable is triggered AND we can use the debounceTime=0 value to search immediately!
		*/

		this.alreadyExecutedSearch = true;
		this.formGroup.patchValue({ debounceTime: 0 })
	}

	get windowWidth(): number {
		return window.innerWidth
	}
}
