import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { deepClone } from '../utils';

import { environment } from "../../environments/environment"

/*
	https://public-api.gbd.globaldb.prd.web1.wipo.int/gs-assistant/exactSearch?term=sushi

	exactSearch can be replace with :

	exactSearch
	semanticSearch
	stringSearch
*/

@Injectable({
	providedIn: 'root'
})
export class SearchService {

	public isLoading: boolean = false

	constructor(private http: HttpClient) { }

	public callSearch(params: any): Observable<any> {
		const l = `ss.callSearch() - `
		// console.log(`${l}received params=`, deepClone(params))

		params.term = encodeURIComponent(params.term);

		let urlSegments = [`${environment.apiURL}/${params.searchType}?query=${params.term}`]
		if(params.inputLang) urlSegments.push(`inputLang=${params.inputLang}`);
		if(params.outputLang) urlSegments.push(`outputLang=${params.outputLang}`);

		const url = urlSegments.join("&")
		this.isLoading = true

		// console.log(`${l}Returning http.get('${url}')`)

		const headers = new HttpHeaders({
			"Authorization": "Bearer 37538A20-D9B3-4D03-BDDA-4C310DAC2A30"
		})
		return this.http.get(url, {headers, responseType: 'text'})
	}
}
