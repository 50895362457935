import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map } from 'rxjs';
import { WOption } from '../interfaces';
import { OptionsListService } from '../_services/options-list.service';

interface TheInterface {
	label: string;
	value: any;
}

@Component({
	selector: 'suggest-multiselect',
	templateUrl: './comp-jer-suggest-multiselect.component.html',
	styleUrls: ['./comp-jer-suggest-multiselect.component.css'],
	providers: [ // Part of the dark magic to implement [(ngModel)]
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: JerSuggestMultiselectComponent,
			multi: true,
		}
	],
})
export class JerSuggestMultiselectComponent implements OnInit {

	/*
		OK so attributes I need :
		[(ngModel)] to know where to store the selected items, and the other way, where to look to populate the component
		[what] to know what to suggest (optional, we can just call Solr to suggest anything)
	*/

	@Input() what: string

	@ViewChild("sgInput") sgInput:ElementRef;

	constructor(public ols: OptionsListService) { }

	ngOnInit(): void {
		const l = `JerSMS ngOnInit() - `
		// console.log(`${l}what=`, this.what)
	}

	onSuggestionClicked(suggestion: WOption) {
		const l = `JerSMS onSuggestionClicked() - `
		// console.log(`${l}suggestion=`, suggestion)

		if (!this.innerValue.find((obj:any) => obj.value === suggestion.value)) {
			this.innerValue.push(suggestion) // This actually pushes things into ngModel :D
		}

		// console.log(`${l}this.sgInput = `, this.sgInput)

		this.sgInput.nativeElement.value="";

		this.onChange(this.innerValue);
	}

	remove(obj: WOption) {
		this.innerValue = this.innerValue.filter((o:any) => o.value !== obj.value)
		this.onChange(this.innerValue);
	}

	/*
		Below : all the dark magic required to implement [(ngModel)] - Don't touch anything
		https://stackblitz.com/edit/angular-custom-comp-ngmodel
	*/

	private innerValue:any;
	private onChange: (_:any) => void;
	private onTouched: () => void;

	get value() {
		return this.innerValue;
	}

	set value(v) {
		if (this.innerValue != v) {
			this.innerValue = v;
			this.onChange(v);
		}
	}

	onBlur() {
		this.onTouched();
	}

	registerOnChange(fn:any) {
		this.onChange = fn;
	}

	registerOnTouched(fn:any) {
		this.onTouched = fn;
	}

	writeValue(v:any) {
		if (this.innerValue != v) {
			this.innerValue = v;
		}
	}

	/*
		END [(ngModel)]
	*/
}
