import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { finalize, Observable } from 'rxjs';
import { environment } from '../../environments/environment'


const localesMapping = { // for Intl.DateTimeFormat and language switching
	ar: "ar-LB",
	de : "de-DE",
	en: "en-US",
	es: "es-ES",
	fr: "fr-FR",
	it : "it-IT",
	ja : "ja-JP",
	ko : "ko-KR",
	pt : "pt-BR",
	ru: "ru-RU",
	zh: "zh-CN",
}


@Injectable({
	providedIn: 'root'
})
export class MechanicsService {

	public lang: string = "en";
	// Language
	public availableLangs: string[] = Object.keys(localesMapping);
	public translations: JSON;

	public isLocalHost: boolean = environment.env.toLowerCase() === 'localhost';
	public isAwsProd: boolean = environment.env.toLowerCase() === 'awsprod';
	public isAwsAcc: boolean = environment.env.toLowerCase() === 'awsacc';
	public isAwsDev: boolean = environment.env.toLowerCase() === 'awsdev';

	/*
	 Hack : when one types in the input field, the transform pipes get called at each key stroke, which is super heavy (especially in exact search, which rebuilds the graphs.)
		I use this lock boolean which is initially set to false, then set to true after one of the transform pipes has run once, then reset to false whenever a HTTP search is made.
	*/
	 public isPipesLocked:boolean = false;

	constructor(public ts: TranslateService) {
		const l: string = `MS constructor - `

		// Extracting the full translations object from Angular translate service :) Cool
		this.ts.onLangChange.subscribe((event: TranslationChangeEvent) => {
			// console.log(`${l}Angular TranslationChangeEvent = `, event);
			this.translations = event.translations
			// console.info(`${l}Set ms.translations = `, this.translations);
		});

		this.switchLang(localStorage.getItem("GSA.lang"))

		document.addEventListener('wipoLanguageChange', ($event:CustomEvent) =>{
			const lang = $event.target["__language"];
			console.log(`${l}wipoLanguageChange event - lang = `, $event.target["__language"])
			this.switchLang(lang)
			localStorage.setItem("GSA.lang", lang)
		})
	}

	switchLang(lang?: string) {

		const l: string = `ms.switchLang() - `

		let routeLang

		try {
			routeLang = window.location.pathname.split("/")[1] // Apparently, activatedRoute.snapshot and stuff aren't yet accessible (a service constructor is quite low-level) so I'm using window.location :P
			routeLang = routeLang.toLowerCase();
			// console.log(`${l}Found lang from URL : ${routeLang}`);
		} catch (err) {
			console.warn(`${l}Could not parse language from URL`);
		}

		lang = lang || routeLang

		if (!lang) {
			// console.log(`${l}Using default language 'en'`);
			lang = 'en';
		} else if (!this.availableLangs.includes(lang)) {
			console.warn(`Language not supported '${lang}'! Falling back to 'en'`)
			lang = "en";
		}

		// console.log(`${l}using lang = ` + lang);

		this.lang = lang;

		// this.initDateFormatter(localesMapping[lang]); // Can be undefined. Will defaut to the user's detected locale
		// this.initNumberFormatter(localesMapping[lang]);

		// Need to recompute all custom keys because DatesHuman :/
		// To do that, I simply subscribe to the TranslateService.onLangChange Observable in SearchService :) Cool

		// Below : if the chosen lang isn't available, TranslationService will automatically fetch the corresponding i18n/JSON language.
		// To execute last, because this emits an "TranslationChangeEvent" that triggers the onTranslationChange subscription in the constructor, and refresh the this.translations JSON. It also triggers every other subscriptions in services and components, to recompute custom keys. We need the dateFormatter to be initialized before
		this.ts.use(lang);
	}

	translate(word: string): string {

		const l: string = `ms.translate() - `
		// console.log(`${l}translating ${word}`);

		/*
			Normally, in templates, the | translate pipe is used.
			But from Typescript code, I can just use the JSON language file (which is extracted from the TranslateService in the constructor of MS).
		*/

		if (!this.translations) {
			// console.log(`${l}Translations object hasn't loaded yet [${word}] - this = `, this);
			// It's async, the translations object hasn't loaded yet
			return "⧗";
		}

		// multilevel support for translation json: level1.level2.level3.level...
		let translation: any = this.translations,
			split = word.split('.');

		try {
			split.forEach(key => { // key = 'status' then 'Expired'; 'page_graphs' then 'graphing'; etc. It allows to drill down
				if (translation) {
					translation = translation[key]; // this drills down in the i18n JSON file, clever
				}
			})
		} catch (err) {
			// console.log(l, err)
		}

		/*
			If the translation cannot be found, we return split[0].

			In the case of "applicant.hyundai motor company" it will return "hyundai motor company"
		*/

		// return translation even if it is set to ""
		if (translation !== undefined) {
			// console.log(`${l}Returning '${word}' translated in '${this.lang}' language = ${translation}`)	
			return translation
		}

		// console.log(`${l}Could not translate '${word}'`)
		return word
	}
}
